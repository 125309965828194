import {
  CHANGE_SIDEBARSHOW,
  OPEN_SIDEBAR,
  CHANGE_LANGUAGE,
  SHOW_LOADING,
  HIDE_LOADING
} from '../actions/types'

const initialState = {
  sidebarShow: true,
  showLanguageSelector: false,
  showLoginModal: false,
  showLoading: false,
  loadingText: '',
  language: 'en'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case CHANGE_SIDEBARSHOW:
      return { ...state, sidebarShow: false }
    case OPEN_SIDEBAR:
      return { ...state, sidebarShow: true }
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: payload
      }
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: true,
        loadingText: payload.text
      }
    case HIDE_LOADING:
      return {
        ...state,
        showLoading: false,
        loadingText: ''
      }
    default:
      return state
  }
}
