import Loader from 'components/loader'
import { history } from 'helpers/history'
import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import ScrollToTopBtn from './components/menu/ScrollToTop'
import Footer from './components/menu/footer'
import Header from './components/menu/header'
import { routes } from './routes'

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location])
  return children
}

const menu = routes.map((route, index) => {
  return route.component ? (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} />}
    />
  ) : null
})

const App = () => {
  const { language } = useSelector((state) => state.store)
  const { i18n } = useTranslation()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (i18n.language !== language) i18n.changeLanguage(language)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [i18n, language])

  return (
    <div className='wraper'>
      <Router history={history}>
        <Suspense fallback=''>
          {loading && <Loader />}
          <Header />
          <ScrollTop path='/'>
            <Switch>{menu}</Switch>
          </ScrollTop>
          <Footer />
          <ScrollToTopBtn />
        </Suspense>
      </Router>
    </div>
  )
}
export default App
