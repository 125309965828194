import { Image } from 'react-bootstrap'
import './index.scss'

const Loader = () => {
  return (
    <div className='loader-container w-100'>
      <div className='loader-wrap'>
        <div className='spinner'></div>
        <Image
          src='./logo.png'
          alt='logo'
          className='logo-img'
        />
      </div>
    </div>
  )
}

export default Loader
