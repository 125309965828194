import {
  CHANGE_SIDEBARSHOW,
  OPEN_SIDEBAR,
  SHOW_LOADING,
  HIDE_LOADING,
  CHANGE_LANGUAGE
} from './types'

export const changeSidebarshow = () => ({
  type: CHANGE_SIDEBARSHOW
})

export const openSidebar = () => ({
  type: OPEN_SIDEBAR
})

export const showLoading = (text = '') => ({
  type: SHOW_LOADING,
  payload: {
    text: text
  }
})

export const hideLoading = () => ({
  type: HIDE_LOADING
})

export const changeLanguage = (language = 'en') => ({
  type: CHANGE_LANGUAGE,
  payload: language
})
