import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import menu from '../../menu-items'
import { useTranslation } from 'react-i18next'

const social = [
  {
    icon: 'fa fa-telegram',
    url: 'https://t.me/apexis',
    cn: 'https://t.me/apexis'
  },
  {
    icon: 'fa fa-instagram',
    url: 'https://www.instagram.com/apexis_io/',
    cn: 'https://www.instagram.com/apexis_io/'
  },
  {
    icon: 'fa fa-youtube-play',
    url: 'https://www.youtube.com/@Apexis',
    cn: 'https://www.youtube.com/@Apexis'
  },
  {
    icon: 'fa fa-twitter',
    url: 'https://twitter.com/apexis_cc',
    cn: 'https://twitter.com/apexis_cc'
  },

  {
    icon: 'fa fa-facebook',
    url: 'https://www.facebook.com/profile.php?id=61555326883848',
    cn: 'https://www.facebook.com/profile.php?id=61555326883848'
  }
]

const Footer = () => {
  const { t, i18n } = useTranslation()

  return (
    <footer className='footer-light'>
      <div className='subfooter'>
        <div className='container'>
          <Row className='footer-menu'>
            <Col
              md='auto'
              xs={12}
              className='mb-3'
            >
              <Image
                src='../logo.png'
                alt='logo'
                style={{ width: '120px' }}
              />
            </Col>

            <Col
              lg={6}
              md
              xs={12}
              className='mb-3'
            >
              <Row className='justify-content-center'>
                {menu.map((menu, index) => (
                  <Col
                    key={index}
                    xs='auto'
                  >
                    <a href={menu.url}>{t(menu.label)}</a>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col
              id='social'
              xs='auto'
              className='text-end'
            >
              {social.map((item, index) => (
                <a
                  key={index}
                  href={i18n.language === 'en' ? item.url : item.cn}
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className={item.icon} />
                </a>
              ))}
            </Col>
          </Row>
          <Row className='text-center mb-3'>
            <span className='copy'>{t('Copyright')} &copy; 2024, Apexis.</span>
          </Row>
        </div>
      </div>
    </footer>
  )
}
export default Footer
