const menu = [
  {
    label: 'Company',
    url: '/company'
  },
  {
    label: 'AI Staking',
    url: '/ai-staking'
  },
  {
    label: 'APEXIS AI',
    url: '/apexis-ai'
  },
  {
    label: 'Research',
    url: '/research'
  },
  {
    label: 'Market',
    url: '/market'
  },
  {
    label: 'Team',
    url: '/team'
  },
  {
    label: 'Contact Us',
    url: '/contact-us'
  }
]

export default menu
