import { useEffect, useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints
} from 'react-socks'
import { changeLanguage } from '../../actions/store'
import config from '../../config'
import menu from '../../menu-items'

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }])

const Header = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [showmenu, btn_icon] = useState(false)
  const ossUrl = config.ossUrl

  useEffect(() => {
    const header = document.getElementById('myHeader')
    const totop = document.getElementById('scroll-to-top')
    const sticky = header.offsetTop
    const scrollCallBack = window.addEventListener('scroll', () => {
      btn_icon(false)
      if (window.pageYOffset > sticky) {
        header.classList.add('sticky')
        totop.classList.add('show')
      } else {
        header.classList.remove('sticky')
        totop.classList.remove('show')
      }
    })
    return () => {
      window.removeEventListener('scroll', scrollCallBack)
    }
  }, [])

  useEffect(() => {
    const language = localStorage.getItem('language')
    if (language) {
      dispatch(changeLanguage(language))
    }
  }, [dispatch])

  const languages = [
    {
      label: 'English',
      value: 'en',
      img: ossUrl + 'uk.png'
    },
    {
      label: '中文',
      value: 'zh',
      img: ossUrl + 'cn.png'
    },
    {
      label: '中文-繁體',
      value: 'tw',
      img: ossUrl + 'tw.png'
    }
  ]

  return (
    <header
      id='myHeader'
      className='navbar'
    >
      <div className='container'>
        <div className='row w-100-nav'>
          <div className='logo'>
            <a href='/'>
              <img
                src='./logo.png'
                className='img-fluid'
                alt='#'
              />
            </a>
          </div>

          <BreakpointProvider>
            {/* MOBILE */}
            <Breakpoint
              l
              down
            >
              {showmenu && (
                <div className='menu'>
                  {menu.map((menu) => {
                    return (
                      <div className='navbar-item'>
                        <a
                          href={menu.url}
                          onClick={() => {
                            localStorage.setItem('accordionKey', undefined)
                          }}
                        >
                          <div className='dropdown-custom btn'>
                            {t(menu.label)}
                            <span className='lines'></span>
                          </div>
                        </a>
                      </div>
                    )
                  })}
                </div>
              )}
            </Breakpoint>

            {/* WEBSITE */}
            <Breakpoint xl>
              <div className='menu'>
                {menu.map((menu) => {
                  return (
                    <div className='navbar-item'>
                      <a
                        href={menu.url}
                        onClick={() => {
                          localStorage.setItem('accordionKey', undefined)
                        }}
                      >
                        <div className='dropdown-custom btn'>
                          {t(menu.label)}
                          <span className='lines'></span>
                        </div>
                      </a>
                    </div>
                  )
                })}
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className='mainside d-flex align-items-center'>
            <a
              className='rounded-pill btn btn-dark btn-sm'
              href={config.portalURL}
            >
              {t('Start Staking')}
            </a>
            <DropdownButton
              title={
                <>
                  <i
                    className='icon_globe-2 me-2'
                    aria-hidden='true'
                  ></i>
                  <span className='text-uppercase'>{i18n.language}</span>
                </>
              }
              id='dropdown-menu'
            >
              {languages.map((lang, index) => {
                return (
                  <>
                    <Dropdown.Item
                      key={index}
                      eventKey={index}
                      onClick={() => {
                        dispatch(changeLanguage(lang.value))
                        localStorage.setItem('language', lang.value)
                      }}
                    >
                      <img
                        src={lang.img}
                        alt='icon'
                      />
                      {t(lang.label)}
                    </Dropdown.Item>
                    {index !== languages.length - 1 && <Dropdown.Divider />}
                  </>
                )
              })}
            </DropdownButton>
          </div>
        </div>

        <button
          className='nav-icon'
          onClick={() => btn_icon(!showmenu)}
        >
          <div className='menu-line white'></div>
          <div className='menu-line1 white'></div>
          <div className='menu-line2 white'></div>
        </button>
      </div>
    </header>
  )
}
export default Header
