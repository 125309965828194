import { lazy } from 'react'

const Company = lazy(() => import('./views/Company'))
const AIStaking = lazy(() => import('./views/AIStaking'))
const ApexisAI = lazy(() => import('./views/ApexisAI'))
const Research = lazy(() => import('./views/Research'))
const Market = lazy(() => import('./views/Market'))
const Team = lazy(() => import('./views/Team'))
const ContactUs = lazy(() => import('./views/ContactUs'))

export const routes = [
  { path: '/', exact: true, name: 'Company', component: Company },
  { path: '/company', name: 'Company', component: Company },
  { path: '/ai-staking', name: 'AI Staking', component: AIStaking },
  { path: '/apexis-ai', name: 'APEXIS AI', component: ApexisAI },
  { path: '/research', name: 'Research', component: Research },
  { path: '/market', name: 'Market', component: Market },
  { path: '/team', name: 'Team', component: Team },
  { path: '/contact-us', name: 'Contact Us', component: ContactUs }
]
